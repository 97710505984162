import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

import Modal from '@components/_shared/modal';
import Button from '@components/_shared/button';
import TextInput from '@components/_shared/textInput';
import { isEmail } from '@utils/helpers';
import { subscribe } from '@api/homepage/subscribe';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

const SignUpForNewsletterModal = (props: Props) => {
  const { open, onClose } = props;
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const isButtonDisabled = !isEmail(email);

  const onSingUpClick = async () => {
    setSubmitting(true);
    await subscribe({
      email,
      tags: ['individual'],
    });
    toast.success('You have successfully signed up for our newsletter');
    setSubmitting(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      containerStyle={styles.signUpForNewsletter}
    >
      <div>
        <div className={styles.signUpForNewsletter__header}>
          <h4>Sign up for our newsletter</h4>

          <div
            className={styles.signUpForNewsletter__closeButton}
            onClick={onClose}
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.89844"
                y="7.47437"
                width="3.2"
                height="16"
                transform="rotate(-45 4.89844 7.47437)"
                fill="#8F8B83"
              />
              <rect
                x="7.16113"
                y="18.7881"
                width="3.2"
                height="16"
                transform="rotate(-135 7.16113 18.7881)"
                fill="#8F8B83"
              />
            </svg>
          </div>
        </div>

        <div className={styles.signUpForNewsletter__divider} />

        <div className={styles.signUpForNewsletter__note}>
          Join our weekly newsletter. Access new listings and get notified about
          availabilities at popular properties.
        </div>

        <div className={styles.signUpForNewsletter__divider} />

        <div className={styles.signUpForNewsletter__body}>
          <TextInput
            label="Email"
            placeholder="john@doe.com"
            onTextChange={setEmail}
            withError={false}
            containerStyle={styles.signUpForNewsletter__email}
          />

          <Button
            containerStyle={styles.signUpForNewsletter__button}
            variant="tanContained"
            loading={submitting}
            disabled={isButtonDisabled}
            onClick={onSingUpClick}
          >
            Sign up for the Newsletter
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpForNewsletterModal;
