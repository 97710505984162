import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import {
  EstimateUpsellAlternativeType,
  ListingPriceEstimateType,
} from '@utils/types/estimate';
import { DatesRange } from '@utils/types/calendar';
import { ListingType } from '@utils/types/listing';
import { getListingInfo, setListingInfo } from '@utils/localStorage';
import { BookingInquiryType } from '@utils/types/inquiry';
import { PaymentMethod } from '@utils/types/paymentMethods';
import { defineSplitPayment } from '@components/payment/utils';
import { da } from 'date-fns/locale';

type ListingFieldsForBooking = {
  listing: ListingType;
  dates: DatesRange;
  bookingID?: string | undefined;
  guestyReservationID?: string | undefined;
  voucher?: string | undefined;
  withPetFee?: boolean | undefined;
  estimate?: ListingPriceEstimateType;
  bookingInquiry?: BookingInquiryType;
  paymentMethod?: CheckoutPaymentMethod;
  guestInfo?: GuestInfo;
  upsellAlternative?: EstimateUpsellAlternativeType;
};

export type CheckoutPaymentMethod = {
  paymentStructure: 'full' | 'monthly';
  type: PaymentMethod['type'] | null;
  id: PaymentMethod['id'] | null;
};

export type GuestInfo = {
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  adultCount?: number;
  childrenCount?: number;
  infantCount?: number;
};

type ContextValues = {
  resetStore: () => void;
  listing?: ListingType;
  listingPriceEstimate?: ListingPriceEstimateType;
  dateRange: DatesRange;
  changeListing: (data: ListingType) => void;
  changeListingPriceEstimate: (data: ListingPriceEstimateType) => void;
  changeDateRange: (data: DatesRange) => void;
  setListingFieldsForBooking: (props: ListingFieldsForBooking) => void;
  bookingID?: string;
  changeBookingID: (id: string) => void;
  guestyReservationID?: string;
  changeGuestyReservationID: (id: string) => void;
  voucher?: string;
  changeVoucher: (code: string | undefined) => void;
  isInstantBooking: boolean;
  withPetFee?: boolean;
  changeWithPetFee: (withPetFee: boolean) => void;
  bookingInquiry?: BookingInquiryType;
  changeBookingInquiry: (bookingInquiry: BookingInquiryType) => void;
  goBackToListing: () => void;
  paymentMethod: CheckoutPaymentMethod;
  changePaymentMethodFields: (fields: Partial<CheckoutPaymentMethod>) => void;
  canSplitPayment: boolean;
  guestInfo: GuestInfo;
  changeGuestInfo: (fields: Partial<GuestInfo>) => void;
  upsellAlternative?: EstimateUpsellAlternativeType;
  changeUpsellAlternative: (value: EstimateUpsellAlternativeType) => void;
  mobileCheckoutConfirmation: boolean;
  setMobileCheckoutConfirmation: (value: boolean) => void;
};

const ListingContext = createContext<ContextValues>({
  resetStore: () => {},
  dateRange: { from: null, to: null },
  changeListing: () => {},
  changeListingPriceEstimate: () => {},
  changeDateRange: () => {},
  setListingFieldsForBooking: () => {},
  changeBookingID: () => {},
  changeGuestyReservationID: () => {},
  changeVoucher: () => {},
  isInstantBooking: false,
  withPetFee: false,
  changeWithPetFee: () => {},
  changeBookingInquiry: () => {},
  goBackToListing: () => {},
  paymentMethod: { paymentStructure: 'full', type: null, id: null },
  changePaymentMethodFields: () => {},
  canSplitPayment: false,
  guestInfo: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  changeGuestInfo: () => {},
  changeUpsellAlternative: () => {},
  mobileCheckoutConfirmation: false,
  setMobileCheckoutConfirmation: () => {},
});
ListingContext.displayName = 'ListingContext';

export function ListingProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const router = useRouter();
  const [listing, setListing] = useState<ListingType>();
  const [listingPriceEstimate, setListingPriceEstimate] =
    useState<ListingPriceEstimateType>();
  const [bookingInquiry, setBookingInquiry] = useState<BookingInquiryType>();
  const [dateRange, setDateRange] = useState<DatesRange>({
    from: null,
    to: null,
  });
  const [bookingID, setBookingID] = useState<string>();
  const [guestyReservationID, setGuestyReservationID] = useState<string>();
  const [voucher, setVoucher] = useState<string | undefined>();
  const [withPetFee, setWithPetFee] = useState(false);
  const [upsellAlternative, setUpsellAlternative] =
    useState<EstimateUpsellAlternativeType>();
  const [paymentMethod, setPaymentMethod] = useState<CheckoutPaymentMethod>({
    paymentStructure: 'full',
    type: null,
    id: null,
  });
  const [guestInfo, setGuestInfo] = useState<GuestInfo>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [mobileCheckoutConfirmation, setMobileCheckoutConfirmation] =
    useState(false);

  // If less than 30 days min stay then the booking is instant
  // PS - if there is a guestyReservationID (inquiry flow) then it is not instant booking
  const isInstantBooking = useMemo(
    () => listing?.min_stay! < 30 && !guestyReservationID,
    [listing, guestyReservationID]
  );

  const resetStore = () => {
    setListing(undefined);
    setListingPriceEstimate(undefined);
    setDateRange({ from: null, to: null });
    setBookingID(undefined);
    setVoucher(undefined);
    setWithPetFee(false);
    setBookingInquiry(undefined);
    setGuestyReservationID(undefined);
    setPaymentMethod({ paymentStructure: 'full', type: null, id: null });
    setGuestInfo({ firstName: '', lastName: '', phoneNumber: '' });
  };

  useEffect(() => {
    if (listing && (listingPriceEstimate || bookingInquiry)) {
      setListingInfo({
        listing,
        dateRange,
        bookingID,
        voucher,
        withPetFee,
        listingPriceEstimate,
        bookingInquiry,
        guestyReservationID,
        paymentMethod,
        guestInfo,
        upsellAlternative,
      });
    }
  }, [
    listing,
    listingPriceEstimate,
    bookingInquiry,
    dateRange,
    bookingID,
    voucher,
    withPetFee,
    guestyReservationID,
    paymentMethod,
    guestInfo,
    upsellAlternative,
  ]);

  const changeListing = useCallback(
    (fields: ListingType) => {
      setListing({ ...listing, ...fields });
    },
    [listing]
  );

  const changeListingPriceEstimate = useCallback(
    (fields: ListingPriceEstimateType) => {
      setListingPriceEstimate({ ...listingPriceEstimate, ...fields });
    },
    [listingPriceEstimate]
  );

  const changeDateRange = (fields: DatesRange) => {
    setDateRange(fields);
  };

  const changeBookingID = (id: string) => {
    setBookingID(id);
  };

  const changeGuestyReservationID = (id: string) => {
    setGuestyReservationID(id);
  };

  const changeVoucher = (discountCode: string | undefined) => {
    setVoucher(discountCode);
  };

  const changeUpsellAlternative = (value: EstimateUpsellAlternativeType) => {
    setUpsellAlternative(value);
  };

  const changeWithPetFee = (val: boolean) => setWithPetFee(val);

  const changeBookingInquiry = (val: BookingInquiryType) => {
    setBookingInquiry(val);
  };

  const setListingFieldsForBooking = useCallback(
    (props: ListingFieldsForBooking) => {
      changeListing(props.listing);
      setDateRange(props.dates);
      setWithPetFee(props.withPetFee || false);
      if (props.bookingID) setBookingID(props.bookingID);
      if (props.estimate) changeListingPriceEstimate(props.estimate);
      if (props.guestyReservationID)
        setGuestyReservationID(props.guestyReservationID);
      if (props.bookingInquiry) setBookingInquiry(props.bookingInquiry);
      if (props.voucher) setVoucher(props.voucher);
      if (props.paymentMethod) setPaymentMethod(props.paymentMethod);
      if (props.guestInfo) setGuestInfo(props.guestInfo);
      if (props.upsellAlternative)
        setUpsellAlternative(props.upsellAlternative);
    },
    [changeListing, changeListingPriceEstimate]
  );

  const goBackToListing = () => {
    if (guestyReservationID) {
      router.push(`/itinerary/${guestyReservationID}`);
    } else {
      router.push({
        pathname: `/listing/${listing?.url_slug}`,
        query: {
          ...(!!dateRange.from && {
            dates: !!dateRange.to
              ? [
                  new Date(dateRange.from).toISOString(),
                  new Date(dateRange.to).toISOString(),
                ]
              : [
                  new Date(dateRange.from).toISOString(),
                  new Date(dateRange.from).toISOString(),
                ],
          }),
        },
      });
    }
  };

  const changePaymentMethodFields = (
    fields: Partial<CheckoutPaymentMethod>
  ) => {
    setPaymentMethod({ ...paymentMethod, ...fields });
  };

  const canSplitPayment = useMemo(
    () => defineSplitPayment(dateRange),
    [dateRange.to, dateRange.from]
  );

  /**
   * If date range is >= 45 days then we split the payment,
   * otherwise we set the payment structure to full
   */
  useEffect(() => {
    changePaymentMethodFields({
      paymentStructure: canSplitPayment ? 'monthly' : 'full',
    });
  }, [canSplitPayment]);

  const changeGuestInfo = (fields: Partial<GuestInfo>) => {
    setGuestInfo({ ...guestInfo, ...fields });
  };

  useEffect(() => {
    if (window.location.pathname.includes('checkout')) {
      const listingFromLocalStorage = getListingInfo();
      if (listingFromLocalStorage) {
        setListingFieldsForBooking({
          ...listingFromLocalStorage,
          estimate: listingFromLocalStorage.listingPriceEstimate,
          dates: listingFromLocalStorage.dateRange,
        });
      } else if (!listing) {
        /*
           redirect from Checkout page to Home page
           if there is no info about Listing in Local Storage
        */
        router.push('/home');
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListingContext.Provider
      value={{
        resetStore,
        listing,
        listingPriceEstimate,
        dateRange,
        changeListing,
        changeListingPriceEstimate,
        changeDateRange,
        setListingFieldsForBooking,
        bookingID,
        guestyReservationID,
        changeGuestyReservationID,
        changeBookingID,
        voucher,
        changeVoucher,
        isInstantBooking,
        withPetFee,
        changeWithPetFee,
        bookingInquiry,
        changeBookingInquiry,
        goBackToListing,
        paymentMethod,
        changePaymentMethodFields,
        canSplitPayment,
        guestInfo,
        changeGuestInfo,
        upsellAlternative,
        changeUpsellAlternative,
        mobileCheckoutConfirmation,
        setMobileCheckoutConfirmation,
      }}
    >
      {children}
    </ListingContext.Provider>
  );
}

export function useListing() {
  const context = useContext(ListingContext);
  if (context === undefined) {
    throw new Error('useListing must be used within an ListingProvider');
  }
  return context;
}
