import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import { CloseButton } from '@components/_shared/closeButton';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  preventPageScroll?: boolean;
  withCloseButton?: boolean;
} & BaseComponentProps;

const customStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    padding: 0,
    border: 'none',
    borderRadius: '3px',
    background: 'transparent',
    overflow: 'visible',
  },
};

const Modal = ({
  open,
  onClose,
  children,
  containerStyle,
  preventPageScroll = true,
  withCloseButton = false,
}: Props) => {
  const { disableScroll, enableScroll } = useToggleBodyScroll();
  useEffect(() => {
    if (preventPageScroll) {
      if (open) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
  }, [open]);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Modal"
      preventScroll
      ariaHideApp={false}
    >
      <div className={clsx([styles.dialogWrapper, containerStyle])}>
        {withCloseButton && (
          <CloseButton onClick={onClose} containerStyle={styles.closeButton} />
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
