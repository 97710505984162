import * as React from 'react';

function BackgroundChecks(props) {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="10.3545"
        cy="10.54"
        r="9"
        transform="rotate(45 10.3545 10.54)"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle
        cx="10.3545"
        cy="10.54"
        r="6.5"
        transform="rotate(45 10.3545 10.54)"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M17.6025 16.3737L26.9717 25.7428C27.167 25.9381 27.167 26.2547 26.9717 26.45L26.2646 27.1571C26.0693 27.3523 25.7527 27.3523 25.5575 27.1571L16.1883 17.7879M17.6025 16.3737L16.1883 17.7879M17.6025 16.3737L17.6026 16.2515M16.1883 17.7879H15.9997"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.49902 11.0015L9.74902 13.0015L13.249 9.00146"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoBackgroundChecks = React.memo(BackgroundChecks);
export default MemoBackgroundChecks;
