import React, { useMemo, useState } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import TextInput from '@components/_shared/textInput';
import Radio from '@components/_shared/radio';
import ParallaxImageSection from '@components/_shared/parallaxImageSection';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { isEmail } from '@utils/helpers';
import { Contacts } from '@utils/constants/global';
import { subscribe, SubscribeTags } from '@api/homepage/subscribe';
import CheckoutLinks from '@components/checkout/links';
import Logo from '@components/layout/logo';
import { ArrowButton } from '@assets/icons';

import styles from './styles.module.scss';

export type FooterProps = {
  browseRentalsLinks: JSX.Element;
  stayLengthCollectionLinks: JSX.Element;
  propertyManagementLinks: JSX.Element;
  noParallaxImage?: boolean;
} & BaseComponentProps;

const Footer = (props: FooterProps) => {
  const {
    browseRentalsLinks,
    stayLengthCollectionLinks,
    propertyManagementLinks,
    containerStyle,
    theme = 'dark',
    noParallaxImage = false,
  } = props;
  const [subscribeTag, setSubscribeTag] = useState<SubscribeTags>('renter');
  const [email, setEmail] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const isButtonDisabled = !isEmail(email) || subscribeSuccess;

  const renderCheckboxes = useMemo(
    () => (
      <div className={styles.footer__checkboxes}>
        <div>I am a:</div>
        {[
          { label: 'Renter', value: 'renter' },
          { label: 'Owner', value: 'owner' },
        ].map(({ label, value }) => (
          <Radio
            key={value}
            label={label}
            containerStyle={styles.footer__checkbox}
            checked={subscribeTag === value}
            onClick={() => setSubscribeTag(value as SubscribeTags)}
            theme={theme}
          />
        ))}
      </div>
    ),
    [subscribeTag, theme]
  );

  const onSubscribeClick = async () => {
    setButtonLoading(true);
    try {
      await subscribe({
        email,
        tags: [subscribeTag],
      });
      setButtonLoading(false);
      setSubscribeSuccess(true);
    } catch (e) {
      setButtonLoading(false);
      setSubscribeSuccess(true);
    }
  };

  return (
    <>
      {!noParallaxImage && (
        <ParallaxImageSection
          uniqueElementID="homepage-parallax-2"
          imageUrl="/images/homepage-parallax-2.png"
          backgroundPositionX="35%"
          theme={theme}
        />
      )}

      <footer className={clsx([styles.footer, styles[theme], containerStyle])}>
        <Link href="/">
          <a className={styles.header__block}>
            <Logo theme={theme} />
          </a>
        </Link>
        <div className={styles.footer__upperBlock}>
          <div>
            <div className={styles.footer__newsletterWrapper}>
              <div className={clsx([styles.footer__subtitle, styles[theme]])}>
                Newsletter
              </div>

              <div className={clsx([styles.footer__inputField, styles[theme]])}>
                <TextInput
                  placeholder="Email"
                  onTextChange={setEmail}
                  theme={theme}
                  withError={false}
                />

                <ArrowButton
                  className={clsx([
                    styles.footer__subscribeButton,
                    isButtonDisabled && styles.disabled,
                  ])}
                  onClick={!isButtonDisabled ? onSubscribeClick : undefined}
                  bgFill={theme === 'dark' ? 'white' : '#1d1919'}
                  arrowFill={theme === 'dark' ? '#1d1919' : 'white'}
                />
              </div>

              {renderCheckboxes}

              {subscribeSuccess && <p>Success!</p>}
            </div>

            <div>
              <div className={clsx([styles.footer__subtitle, styles[theme]])}>
                Contact Us
              </div>
              <div className={clsx([styles.footer__link])}>
                <a
                  target="_blank"
                  href={Contacts.FACEBOOK_URL}
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </div>
              <div className={clsx([styles.footer__link])}>
                <a
                  target="_blank"
                  href={Contacts.INSTAGRAM_URL}
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
              <div className={clsx([styles.footer__link])}>
                <a href={`tel:${Contacts.CALL_US}`}>Call Us</a>
              </div>
              <div className={clsx([styles.footer__link])}>
                <a href={`mailto:${Contacts.CONTACT_EMAIL}`}>Email Us</a>
              </div>
              <div className={clsx([styles.footer__link])}>
                <a
                  target="_blank"
                  href={Contacts.WHATSAPP_URL}
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>
              </div>
            </div>
          </div>

          <div>
            <div className={clsx([styles.footer__subtitle, styles[theme]])}>
              Stay
            </div>
            <div className={styles.footer__links}>{browseRentalsLinks}</div>
          </div>

          <div>
            <div className={clsx([styles.footer__subtitle, styles[theme]])}>
              List Your Home
            </div>
            <div className={styles.footer__links}>
              {propertyManagementLinks}
            </div>
            <div className={styles.footer__links} style={{ marginTop: '40px' }}>
              {stayLengthCollectionLinks}
            </div>
          </div>

          <div>
            <div className={clsx([styles.footer__subtitle, styles[theme]])}>
              Company
            </div>
            <div className={clsx([styles.footer__link])}>
              <a
                target="_blank"
                href={Contacts.CAREERS_SITE_URL}
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </div>
            <div className={clsx([styles.footer__link])}>
              <Link href="/blog">
                <a>Blog</a>
              </Link>
            </div>
            <div className={clsx([styles.footer__link])}>
              <Link href={CheckoutLinks.TERMS_OF_SERVICE}>
                <a>Terms of Service</a>
              </Link>
            </div>
            <div className={clsx([styles.footer__link])}>
              <Link href={CheckoutLinks.TERMS_OF_SERVICE}>
                <a>Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>

        <div className={clsx([styles.footer__lowerBlock, styles[theme]])}>
          <p>
            {new Date().getFullYear()} Rove travel, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
