import * as React from 'react';

function Insurance(props) {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.50146C9.96512 2.41268 8.11628 4.19305 1 4.02483V10.5435C1 21.1417 7 23.9314 10 24.0015C13 23.9314 19 21.1417 19 10.5435V4.02483C11.8837 4.19305 10.0349 2.41268 10 1.50146Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.5015L9.25 14.5015L12.75 10.5015"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoInsurance = React.memo(Insurance);
export default MemoInsurance;
