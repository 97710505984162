import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { Cross } from '@assets/icons';

import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
} & BaseComponentProps;

export function CloseButton(props: Props) {
  const { containerStyle, onClick } = props;
  return (
    <div className={clsx([styles.closeButton, containerStyle])}>
      <Cross onClick={onClick} />
    </div>
  );
}
